/* REACT IMPORTS */
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

/* STYLES */
import "../styles/global.css";

/* LAYOUT */
import Layout from "../layout";

/* PAGES */
import AuthLogin from "../pages/auth/login";
import ContactUs from "../pages/dashboard/contact-us";
import RegistrationForm from "../pages/dashboard/registration-form";
import RsvpForm from "../pages/dashboard/rsvp";
import RsvpRequestForm from "../pages/dashboard/rsvp-request";
import MeetingAbsence from "pages/dashboard/meeting-absence";

/* SCROLL TO TOP */
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const AppRoutes = () => {
  return (
    <div className="app">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<AuthLogin />} />

          {/* CONTACT US */}
          <Route
            path="/contact-us"
            element={
              <Layout>
                <ContactUs />
              </Layout>
            }
          />

          {/* REGISTRATION FORM */}
          <Route
            path="/registration"
            element={
              <Layout>
                <RegistrationForm />
              </Layout>
            }
          />

          {/* RSVP REQUEST FORM */}
          <Route
            path="/awards-rsvp-request"
            element={
              <Layout>
                <RsvpRequestForm />
              </Layout>
            }
          />

          {/* RSVP FORM */}
          <Route
            path="/rsvp"
            element={
              <Layout>
                <RsvpForm />
              </Layout>
            }
          />

          <Route
            path="/meeting-absence"
            element={
              <Layout>
                <MeetingAbsence />
              </Layout>
            }
          />
        </Routes>
      </Router>
    </div>
  );
};

export default AppRoutes;
