/* LAYOUT COMPONENTS */
import Sidebar from "./sidebar";
import Header from "./header";

const Layout = ({ children }) => {
  return (
    <section className="bg-body overflow-hidden ">
      <div className="container-full-width h-screen">
        <div className="block xl:flex lg:flex items-start">
          <div className="hidden xl:block lg:block">
            <Sidebar />
          </div>
          <div className="flex-shrink-0 flex-1">
            <div className="px-0 xl:px-3 lg:px-3 pt-3">
              <Header />
            </div>
            <div className="h-auto xl:h-mainSection lg:h-mainSection overflow-auto overflow-x-hidden hide-scrollbar">
              {children}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Layout;
